import styled from "@emotion/styled"
import tw from "twin.macro"

export const Wrapper = styled.div`
  ${tw`relative flex justify-start cursor-pointer select-none`}
  ${props => (props.centered ? tw`items-center` : tw`items-start py-1-1`)}
`
export const Label = styled.label`
  ${tw`tracking-relaxed pointer-events-none`}
  ${props => (props.large ? tw`text-sm leading-looser` : tw`text-xs`)}
  ${props => (!props.centered ? tw`-mt-0-4` : ``)}
  ${props => (props.capitalize ? tw`capitalize` : ``)}
`
export const SquareOuter = styled.div`
  ${tw`mr-1 border transition-all duration-200 pointer-events-none`}
  ${props => (props.checked ? tw`border-grey-darker` : tw`border-grey-mid group-hover:border-grey-darker`)}
  ${props => (props.rounded ? tw`rounded-full` : ``)}
`
export const SquareInner = styled.div`
  ${tw`w-0-8 h-0-8 m-0 border border-light transform transition-all duration-200`}
  ${props => (props.checked ? tw`bg-grey-darker scale-100` : tw`scale-0`)}
  ${props => (props.rounded ? tw`rounded-full m-0` : ``)}
`
