import React, { Fragment } from "react"

import { withCustomerPreferencesForm } from "./withCustomerPreferencesForm"
import { Checkbox } from "../../../Styled/Checkbox/Checkbox"
import {
  Form,
  FormSubheading,
  FormButton,
  FormButtonWrapper,
  FormInputWrapper,
  FormCheckboxItem,
  FormLargeNote,
  FormError,
} from "../../../Styled/Form"

export const CustomerPreferencesForm = withCustomerPreferencesForm(
  ({ changed, customer, errors, fields, handleChange, handleSubmit, loading, locales }) => (
    <Form onSubmit={handleSubmit} margin={`true`}>
      {fields.map(field => (
        <Fragment key={field.name}>
          <FormSubheading>{locales[field.name]}</FormSubheading>

          <FormInputWrapper>
            <FormCheckboxItem>
              <Checkbox checked={customer[field.name]} externalState name={field.name} onChange={handleChange}>
                {locales[`${field.name}True`]}
              </Checkbox>
            </FormCheckboxItem>

            <FormCheckboxItem>
              <Checkbox checked={!customer[field.name]} externalState name={field.name} onChange={handleChange}>
                {locales[`${field.name}False`]}
              </Checkbox>
            </FormCheckboxItem>
          </FormInputWrapper>

          {locales[`${field.name}Description`] ? <FormLargeNote>{locales[`${field.name}Description`]}</FormLargeNote> : null}
        </Fragment>
      ))}

      <FormButtonWrapper>
        <FormButton disabled={loading || !changed} type={`submit`}>
          {locales?.save}
        </FormButton>
      </FormButtonWrapper>

      {errors?.length > 0 && errors?.map(({ code, message }) => <FormError key={code}>{message}</FormError>)}
    </Form>
  )
)
