import React, { useState } from "react"

import { Label, Wrapper, SquareInner, SquareOuter } from "./CheckboxStyles"

interface Props {
  capitalize?: boolean
  centered?: boolean
  checked?: boolean
  children?: any
  externalState?: boolean
  id?: string
  isChecked?: boolean
  large?: boolean
  name?: string
  handleClick?: any
  rounded?: boolean
  onChange?: any
  isFilter?: boolean
}

export const Checkbox = ({ capitalize, centered, checked, children, externalState, id, large, name, onChange, rounded, isFilter = false }: Props) => {
  const [isChecked, setChecked] = useState(checked ? checked : false)
  const handleClick = () => {
    const state = !isChecked

    if (!externalState) {
      setChecked(state)
    }
    if (onChange) {
      if (!isFilter) {
        onChange({ checked: state, id, name, type: `checkbox` })
      } else {
        onChange()
      }
    }
  }

  return (
    <Wrapper id={id} name={name} className={`group`} onClick={handleClick} centered={centered}>
      <SquareOuter checked={externalState || isFilter ? checked : isChecked} rounded={rounded}>
        <SquareInner checked={externalState || isFilter ? checked : isChecked} rounded={rounded} />
      </SquareOuter>

      {children && (
        <Label capitalize={capitalize} centered={centered} large={large}>
          {children}
        </Label>
      )}
    </Wrapper>
  )
}
