import React from "react"

import { useLanguage } from "@hooks/useLanguage"
import { Account } from "../../Account/Account"

export const withCustomerPreferences = Component => ({ name = `CustomerPreferences`, ...props }) => {
  const locales = useLanguage(`account`)

  Component.displayName = name
  return (
    <Account {...props} description={locales.preferencesDescription} title={locales.preferencesTitle}>
      <Component />
    </Account>
  )
}
